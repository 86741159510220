.new-checkout {
    .card-price-xs-lg-error {
        opacity: 0.47;
        background: #AAA;
    }

    .total-free {
        color: #2EC76B;
    }

    .total-default {
       padding-left: 5%;
       width: 85%;
       display: flex;
       justify-content: right;
       font-size: 17px;
       font-weight: bold;
    }
}

.card-price-xs-lg {
    width: 100% !important;
}